/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { FaqAccordion } from '@components/Accordion';
import { Spinner } from '@primitives';
import { useStores } from '@stores';
import { observer } from 'mobx-react';
// @ts-ignore
import featuredImage from '../../resources/images/liinakonkatu2.png';
import { FormattedMessage as M, useIntl } from 'gatsby-plugin-intl';

interface ILivingFaq {
}

const LivingFaqPage: FC<ILivingFaq> = observer(() => {
  const { faqStore: { getFaqList, faqLists, state } }: { faqStore: IFaqStore } = useStores();

  const intl = useIntl();
  const currentLanguage = intl.locale;

  useEffect(() => {
    if (currentLanguage) {
      getFaqList('front-page', currentLanguage);
    }
  }, []);

  const faqList = faqLists.find((faqList) => faqList.slug === 'front-page');
  return (
    <ContentPageLayout slug="faq" featuredImageFile={featuredImage}>
      {state === 'Loading' && <Spinner />}
      {faqList?.faqs && (<FaqAccordion id="living-faq" items={faqList?.faqs} />)}
    </ContentPageLayout>
  );
});

export default LivingFaqPage;
